<div *ngIf="isHomePage" class="special-offer-handler" (click)="specialOfferOnClick()"></div>

<app-happy-hours></app-happy-hours>

<!--<div-->
<!--  [class.visible]="showAds"-->
<!--  [class.hidden]="!showAds"-->
<!--  class="ads"-->
<!--  routerLink="/main/home/news"-->
<!--  routerLinkActive="active"-->
<!--&gt;-->
<!--  <div class="ads-content">-->
<!--    <img class="national" src="/images/national_post.svg" alt="National Post">-->
<!--    <img class="national" src="/images/national_post.svg" alt="National Post">-->
<!--    <img class="national" src="/images/national_post.svg" alt="National Post">-->
<!--    <img class="national" src="/images/national_post.svg" alt="National Post">-->
<!--  </div>-->
<!--</div>-->

<div [@homeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { BpMoreComponent } from './bp-more/bp-more.component';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BpRewardsComponent } from './bp-rewards/bp-rewards.component';
import { QrViewComponent } from '../qr-view/qr-view.component';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';
import { SpecialTabComponent } from '@app/pages/main-page/special-tab/special.component';

type State = 'main' | 'rewards' | 'promo' | 'gift' | 'more';

@Component({
  selector: 'app-bp-sc-sections',
  standalone: true,
  templateUrl: './bp-sc-sections.component.html',
  styleUrls: ['./bp-sc-sections.component.scss'],
  imports: [
    BpMoreComponent,
    NgIf,
    TranslateModule,
    BpRewardsComponent,
    QrViewComponent,
    SpecialTabComponent,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  animations: [
    trigger('InOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class BpScSectionsComponent {

  public state: State = 'main';

  public get isMain(): boolean {
    return this.state === 'main';
  }

  public get isRewards(): boolean {
    return this.state === 'rewards';
  }

  public get isPromo(): boolean {
    return this.state === 'promo';
  }

  public get isGift(): boolean {
    return this.state === 'gift';
  }

  public setState(state: State): void {
    this.state = state;
  }
}

<div class="content" #content coreActivitySection="Specials">
  <div class="back-navigation" (click)="back()" coreUserActivityClickTracking="CloseSpecials">
    <img src="/images/back_button.svg" alt="">
  </div>

  @if (!isLoading) {
    @if (specials.length > 0) {
      <swiper-container #swiper class="swiper" autoplay-delay="5000">
        @for (slide of specials; track slide; let i = $index) {
          <swiper-slide>
            <img class="slide" [src]="slide.contentLocal" alt="Image {{ i + 1 }}" />
          </swiper-slide>
        }
      </swiper-container>
    } @else {
      <div class="empty">
        <img src="images/promos_offers.png" alt="" />
        {{ 'serviceCenter.sections.promo.empty' | translate }}
      </div>
    }

  } @else {
    <app-loading></app-loading>
  }
</div>


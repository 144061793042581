import { Routes } from '@angular/router';
import { MainPageComponent } from './pages';
import { MenuTabComponent } from './pages/main-page/menu-tab/menu-tab.component';
import { HomeTabComponent } from './pages/main-page/home-tab/home-tab.component';
import { SpecialOfferComponent } from './pages/main-page/special-offer/special-offer.component';
import { InvoiceTabComponent } from './pages/main-page/invoice-tab/invoice-tab.component';
import { ServiceCentreComponent } from './pages/main-page/service-centre/service-centre.component';
import { NewsPageComponent } from './pages/main-page/news-page/news-page.component';

export const appRoutes: Routes = [
  {
    path: 'main',
    component: MainPageComponent,
    children: [
      {
        path: 'menu',
        component: MenuTabComponent,
        data: { animation: 'MenuTab', screen: 'Menu' },
      },
      {
        path: 'home',
        component: HomeTabComponent,
        data: { animation: 'HomeTab', screen: 'Home' },
        children: [
          {
            path: 'service',
            component: ServiceCentreComponent,
            data: { animation: 'ServiceCentre', screen: 'ServiceCentre' },
          },
          {
            path: 'special-offer',
            component: SpecialOfferComponent,
            data: { animation: 'SpecialOffer', screen: 'SpecialOffer' },
          },
          {
            path: 'news',
            component: NewsPageComponent,
            data: { animation: 'ServiceCentre', screen: 'News' },
          },
        ],
      },
      {
        path: 'invoice',
        component: InvoiceTabComponent,
        data: { animation: 'InvoiceTab', screen: 'Invoice' },
      },
    ],
  },
];

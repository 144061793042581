<app-loading *ngIf="isLoading; else content"></app-loading>

<ng-template #content >
  <div class="categories" coreActivitySection="MenuCategories">
    <ng-container *ngIf="isHHNav">
      <button class="category happy-hours"
              *ngFor="let menu of happyHoursMenus"
              [class.active]=isActiveCategory(menu.id)
              (click)="setActiveTab('tabHappyHours', menu.id); setHappyHoursMenusCurrent(menu)"
              coreUserActivityClickTracking="HappyHoursOpen"
      >
        <img class="icon happy-hours" src="/images/appy_hour_item.png" alt="">
      </button>
    </ng-container>
    <button class="category"
            *ngFor="let menu of mainMenus"
            [class.active]=isActiveCategory(menu.id)
            (click)="setActiveTab('tabMain', menu.id); setMainMenusCurrent(menu);"
            [coreUserActivityClickTracking]="menu.name.split(' ').join('') + 'Open'"
    >
      <img *ngIf="!menu.iconLocal" class="icon" src="/images/menu.svg" alt="">
      <img *ngIf="menu.iconLocal" class="icon" [src]="menu.iconLocal" alt="">
      <span class="name" [class.active]=isActiveCategory(menu.id)>
          {{ menu.name }}
        </span>
    </button>
    <ng-container *ngIf="!isHHNav">
      <button class="category happy-hours"
              [class.active]=isActiveCategory(menu.id)
              *ngFor="let menu of happyHoursMenus"
              (click)="setActiveTab('tabHappyHours', menu.id); setHappyHoursMenusCurrent(menu)"
              coreUserActivityClickTracking="HappyHoursMenuOpen"
      >
        <img class="icon happy-hours" src="/images/appy_hour_item.png" alt="">
      </button>
    </ng-container>
  </div>

  <div class="empty" *ngIf="happyHoursMenus.length === 0 && mainMenus.length === 0 else menus">
    <img src="/images/menu.svg" alt="" />
    <div>{{ 'menu.empty' | translate }}</div>
  </div>

  <ng-template #menus>
    <div class="menu" *ngIf="tabs.activeId === 'tabMain'">
      <pinch-zoom *ngFor="let url of mainMenusCurrent ? mainMenusCurrent.pagesLocal : mainMenus[0].pagesLocal">
        <img [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    </div>

    <div class="menu" *ngIf="tabs.activeId === 'tabHappyHours'">
      <pinch-zoom *ngFor="let url of happyHoursMenusCurrent ? happyHoursMenusCurrent.pagesLocal : happyHoursMenus[0].pagesLocal">
        <img [src]="url" loading="lazy" alt="">
      </pinch-zoom>
    </div>
  </ng-template>
</ng-template>


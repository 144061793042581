import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-bp-rewards',
  standalone: true,
  templateUrl: './bp-rewards.component.html',
  imports: [
    TranslateModule,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./bp-rewards.component.scss'],
})
export class BpRewardsComponent {
}

<div class="back-navigation" [routerLink]="showQR ? '/main/home/news' : '/main/home'" (click)="showQR = false">
  <img src="/images/back_button.svg" alt="">
</div>
<div class="title" *ngIf="!showQR">
  <img class="national" src="/images/national_post.svg" alt="National Post">
</div>
<div *ngIf="rssItems.length; else loading" class="content">
  <div *ngIf="!showQR" class="news">
    <div *ngFor="let item of rssItems" class="news__item" (click)="showQR = true">
      <h3 class="news__item__title">{{ item.title }}</h3>
      <div class="news__item__description" [innerHTML]="sanitizeContent(item.description)"></div>
      <div class="news__item__details">
        <span>{{ item.creator }}</span>
        <span>{{ item.pubDate }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="showQR" (click)="showQR = false" class="news-qr">
    <img class="national" src="/images/national_post.svg" alt="National Post">
    <div class="qr-title">
      More news here
    </div>
    <img class="qr" src="/images/news-qr.svg" alt="QR-code">
  </div>
</div>


<ng-template #loading>
  <app-loading></app-loading>
</ng-template>

import { AfterContentInit, Component, DestroyRef, ElementRef, HostListener, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivitySectionDirective,
  HappyHoursService,
  IMenu,
  MenusService,
  UserActivityClickTrackingDirective,
} from '@core';
import { LoadingComponent } from '@app/components/loading/loading.component';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';

const mainTabId = 'tabMain';
const happyHoursTabId = 'tabHappyHours';

@Component({
  standalone: true,
  selector: 'app-menu-tab',
  templateUrl: 'menu-tab.component.html',
  styleUrl: 'menu-tab.component.scss',
  imports: [
    NgIf,
    NgForOf,
    TranslateModule,
    LoadingComponent,
    PinchZoomModule,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
})
export class MenuTabComponent implements OnInit, AfterContentInit {

  public readonly tabs = {
    isSticky: false,
    activeId: mainTabId,
    menuId: '',
  };

  public readonly pinchZoomProperties = {
    wheel: false,
    autoZoomOut: true,
    backgroundColor: 'rgba(240, 240, 243, 0.9)',
  };

  public mainMenus: IMenu[] = [];
  public mainMenusCurrent: IMenu | null = null;

  public happyHoursMenus: IMenu[] = [];
  public happyHoursMenusCurrent: IMenu | null = null;
  public isHHNav = false;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly element: ElementRef,
    private readonly router: Router,
    private readonly menusService: MenusService,
    private readonly happyHoursService: HappyHoursService,
  ) {}

  get isLoading(): boolean {
    return this.menusService.loading$.getValue();
  }

  get isBackTabShow(): boolean {
    return (
        this.tabs.activeId === 'tabMain'
        && this.mainMenus.length > 1
        && !!this.mainMenusCurrent
      )
      || (
        this.tabs.activeId === 'tabHappyHours'
        && this.happyHoursMenus.length > 1
        && !!this.happyHoursMenusCurrent
      );
  }

  get isTabsShow(): boolean {
    return this.isBackTabShow || (
      this.happyHoursMenus.length !== 0
      && this.mainMenus.length !== 0
    );
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.setActiveTab(this.tabs.activeId);
    });
  }

  ngAfterContentInit(): void {
    this.menusService.menus$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((menus) => {
      this.mainMenus = menus.filter(
        (menu) => !menu.happyHours && !menu.hidden,
      );
      this.happyHoursMenus = menus.filter(
        (menu) => menu.happyHours && !menu.hidden,
      );
      this.clearMenusCurrents();

      if (this.mainMenus.length === 0 && this.happyHoursMenus.length !== 0) {
        this.setActiveTab(happyHoursTabId);
      }

      if (this.mainMenus.length !== 0 && this.happyHoursMenus.length === 0) {
        this.setActiveTab(mainTabId);
      }
    });

    this.happyHoursService.isNow$.pipe(
      take(1),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((yes) => {
      const navigationState = history.state;

      this.isHHNav = yes;
      yes =
        (
          yes || this.mainMenus.length === 0 || navigationState?.tabHH
        ) &&
        this.happyHoursMenus.length !== 0;

      this.setActiveTab(yes ? happyHoursTabId : mainTabId);
    });
  }

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    this.tabs.isSticky = this.element.nativeElement.scrollTop >= 20;
  }

  setMainMenusCurrent(menu: IMenu | null): void {
    this.mainMenusCurrent = menu;
  }

  setHappyHoursMenusCurrent(menu: IMenu | null): void {
    this.happyHoursMenusCurrent = menu;
  }

  clearMenusCurrents(): void {
    this.setMainMenusCurrent(
      this.mainMenus.length === 1 ? this.mainMenus[0] : null,
    );

    this.setHappyHoursMenusCurrent(
      this.happyHoursMenus.length === 1 ? this.happyHoursMenus[0] : null,
    );
  }

  setActiveTab(id: string, menuId?: number): void {
    this.tabs.activeId = id;
    if (menuId) {
      this.tabs.menuId = menuId.toString();
    }

    this.clearMenusCurrents();

    this.element.nativeElement.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  isActiveCategory(menuId: number): boolean {
    if ((
      this.mainMenusCurrent || this.happyHoursMenusCurrent
    ) && this.tabs.menuId) {
      return this.tabs.menuId === menuId.toString();
    }
    else if (this.tabs.activeId === 'tabMain') {
      return this.mainMenus[0].id === menuId;
    }
    else {
      return this.happyHoursMenus[0].id === menuId;
    }
  }

}

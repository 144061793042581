import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { fromEvent, interval, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@UntilDestroy()
@Component({
  selector: 'app-bp-more',
  standalone: true,
  templateUrl: './bp-more.component.html',
  styleUrls: ['./bp-more.component.scss'],
  imports: [
    TranslateModule,
    NgIf,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  animations: [
    trigger('slideAnimation', [
      transition(':increment', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':decrement', [
        style({ transform: 'translateY(-100%)' }),
        animate('300ms ease-out', style({ transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('InOutAnimation', [
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class BpMoreComponent implements AfterViewInit {

  @Output() backToSections = new EventEmitter<boolean>();

  public index = 0;
  public isAnimating = false;

  @ViewChild('content', { static: false })
  private content!: ElementRef;

  ngAfterViewInit(): void {
    fromEvent<TouchEvent>(this.content.nativeElement, 'touchstart', { passive: true }).subscribe(startEvent => {
      const startY = startEvent.changedTouches[0].screenY;

      const touchEndSubscription = fromEvent<TouchEvent>(
        this.content.nativeElement,
        'touchend',
        { passive: true },
      ).subscribe(endEvent => {
        const endY = endEvent.changedTouches[0].screenY;

        const difference = startY - endY;
        const direction = difference <= 20 && difference >= -20
          ? 'click'
          : difference > 20
            ? 'up'
            : 'down';

        this.checkDirection(direction);

        touchEndSubscription.unsubscribe();
      });
    });
    interval(5000).pipe(
      takeUntil(merge(
        fromEvent(this.content.nativeElement, 'touchmove', { passive: true }),
        fromEvent(this.content.nativeElement, 'mousemove', { passive: true }),
        fromEvent(this.content.nativeElement, 'click', { passive: true }),
      )),
      untilDestroyed(this),
    ).subscribe(() => {
      if (!this.isAnimating) {
        this.getNext();
      }
    });
  }

  public back(): void {
    this.backToSections.emit(true);
  }

  getPrev(): void {
    if (!this.isAnimating) {
      this.isAnimating = true;
      this.index = (
        this.index - 1 + 6
      ) % 6;
      setTimeout(() => this.isAnimating = false, 300);
    }
  }

  getNext(): void {
    if (!this.isAnimating) {
      this.isAnimating = true;
      this.index = (
        this.index + 1
      ) % 6;
      setTimeout(() => this.isAnimating = false, 300);
    }
  }

  shouldShowPrevious(index: number): boolean {
    const prevIndex = (
      this.index - 1 + 6
    ) % 6;
    return index === prevIndex;
  }

  shouldShowNext(index: number): boolean {
    const nextIndex = (
      this.index + 1
    ) % 6;
    return index === nextIndex;
  }

  getAnimationState(index: number): string {
    if (index === this.index) {
      return 'active';
    }
    else if (index === (
      this.index - 1 + 6
    ) % 6) {
      return 'previous';
    }
    else if (index === (
      this.index + 1
    ) % 6) {
      return 'next';
    }
    else {
      return '';
    }
  }

  private checkDirection(direction: string): void {
    if (direction !== 'click') {
      direction === 'up'
        ? this.getNext()
        : this.getPrev();
    }
  }
}

import {
  AfterViewChecked,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { map, observeOn } from 'rxjs/operators';
import { animationFrameScheduler, BehaviorSubject } from 'rxjs';
import { ActivitySectionDirective, ISpecial, SpecialsStorageService, UserActivityClickTrackingDirective } from '@core';
import { LoadingComponent } from '@app/components/loading/loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { register, SwiperContainer } from 'swiper/element/bundle';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-special',
  standalone: true,
  templateUrl: './special.component.html',
  styleUrl: './special.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    LoadingComponent,
    TranslateModule,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
})
export class SpecialTabComponent implements OnInit, AfterViewChecked {
  @Output() backToSections = new EventEmitter<boolean>();

  @ViewChild('swiper', { static: false }) swiperRef!: ElementRef<SwiperContainer>;

  public specials: ISpecial[] = [];
  public readonly loading$ = new BehaviorSubject<boolean>(true);
  private swiperInitialized = false;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly specialsStorage: SpecialsStorageService,
  ) {
    this.loading$.next(true);
  }

  get isLoading(): boolean {
    return this.loading$.getValue();
  }

  ngOnInit(): void {
    this.specialsStorage.getAllSpecials().pipe(
      observeOn(animationFrameScheduler),
      takeUntilDestroyed(this.destroyRef),
      map((specials: ISpecial[]) => specials.sort((a, b) => a.sequence - b.sequence)),
    ).subscribe((specials: ISpecial[]) => {
      this.specials = specials;
      this.loading$.next(false);
    });
  }

  ngAfterViewChecked(): void {
    if (this.specials.length > 1 && this.swiperRef && !this.swiperInitialized) {
      const swiper = this.swiperRef.nativeElement;
      swiper.setAttribute('pagination', 'true');
      swiper.setAttribute('direction', 'vertical');
      swiper.setAttribute('loop', 'true');
      swiper.setAttribute('verticalClass', 'test');
      this.swiperInitialized = true;
    }
  }

  public back(): void {
    this.backToSections.emit(true);
  }
}

register();

<div class="back-navigation" coreActivitySection="Rewards">
  <img src="/images/back_button.svg" alt="" coreUserActivityClickTracking="CloseRewards">
</div>

<div class="box">
  <ng-container>
    <img class="logo" src="/images/logo_service_centre.svg" alt="">
    <div class="title">{{ 'serviceCenter.sections.rewards.title' | translate }}</div>
    <div class="description">
      <span class="first"> {{ 'serviceCenter.sections.rewards.description' | translate }}</span>
      <span class="second"> {{ 'serviceCenter.sections.rewards.condition' | translate }}</span>
    </div>
    <div class="qrs">
      <img class="qrImg" src="/images/qr_myBp.svg" alt="">
    </div>
  </ng-container>
</div>

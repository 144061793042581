<div class="back-navigation" coreActivitySection="GiftCard">
  <img src="/images/back_button.svg" alt="" coreUserActivityClickTracking="GiftCardClose">
</div>

<div class="box" coreUserActivityClickTracking="GiftCardClose">
  <ng-container>
    <img class="logo" src="/images/logo_service_centre.svg" alt="">
    <div class="title">{{ 'serviceCenter.sections.gift.title' | translate }}</div>
    <img class="qrImg" src="/images/qr_gift_cards.svg" alt="">
  </ng-container>
</div>
